
import { Parallax, ProfileForm } from "@/components";
import { storage, usersCollection } from "@/plugins/firebase";
import { defineComponent } from "@vue/runtime-core";
import { mapState } from "vuex";

export default defineComponent({
  name: "Profile",
  components: { Parallax, ProfileForm },
  data() {
    return {
      userName: "",
      profilePictureUrl: ""
    };
  },
  methods: {
    async uploadNewPicture(val: any) {
      try {
        const storageRef = storage.ref().child(`profilePictures/${this.userId}`);
        await storageRef.put(val.file);

        const profilePicUrl = await storageRef.getDownloadURL();
        await usersCollection.doc(this.userId).update({ profilePicUrl });
        this.profilePictureUrl = profilePicUrl;
      } catch (err) {
        console.warn(err);
      }
    },
    getUserNameAndPicture(val: { profilePicture: string; name: string }) {
      this.profilePictureUrl = val.profilePicture;
      this.userName = val.name;
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.auth.userId
    })
  }
});
